import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { SignalRClientService } from '@shared/services/signalr-client.service';
import { Subject } from 'rxjs';

const URL = '/signalr-alarming';

type NotificationsCountChangedMessage = {
    projectId: number;
    count: number;
};

@Injectable({
    providedIn: 'root',
})
export class AlarmNotificationsSignalRService extends SignalRClientService {
    private readonly notificationsCountChangedMessageSource = new Subject<NotificationsCountChangedMessage>();
    notificationsCountChanged$ = this.notificationsCountChangedMessageSource.asObservable();

    constructor() {
        super(URL);
    }

    startConnection(): void {
        this.connect().subscribe((connection) => this.registerEvents(connection));
    }

    stopConnection(): void {
        this.disconnect();
    }

    private registerEvents(connection: HubConnection): void {
        connection.on('NotificationsCountChanged', (data: NotificationsCountChangedMessage) => {
            this.notificationsCountChangedMessageSource.next(data);
        });
    }
}
