import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToLocalDateString',
})
export class DateToLocalDateStringPipe implements PipeTransform {
    transform(value: moment.Moment): string {
        if (!value) return '';
        return new Date(value.valueOf()).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
}
