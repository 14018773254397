import { Component, Injector, OnDestroy, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProjectListDto, ProjectsServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-select-project-modal',
    templateUrl: './select-project-modal.component.html',
    styleUrls: ['./select-project-modal.component.css'],
})
export class SelectProjectModalComponent extends AppComponentBase implements OnDestroy {
    @ViewChild(ModalDirective) modal: ModalDirective;
    @ViewChild(Table) dataTable: Table;
    @ViewChild(Paginator) paginator: Paginator;

    nameFilter = '';

    private readonly projectSource = new Subject<ProjectListDto>();
    private project$ = this.projectSource.asObservable();

    private destroy$ = new Subject<void>();

    constructor(
        injector: Injector,
        private projectsService: ProjectsServiceProxy,
    ) {
        super(injector);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    show(): Observable<ProjectListDto> {
        this.primengTableHelper.totalRecordsCount = 0;
        this.primengTableHelper.records = [];
        this.nameFilter = '';
        this.dataTable.clear();
        this.modal.show();
        return this.project$;
    }

    getProjects(event?: LazyLoadEvent): void {
        if (this.dataTable && this.paginator) {
            if (this.primengTableHelper.shouldResetPaging(event)) {
                this.primengTableHelper.resetPaging(this.paginator);
                return;
            }

            this.primengTableHelper.showLoadingIndicator();

            const sorting = this.primengTableHelper.getSortKey(this.dataTable);

            const skipCount = this.primengTableHelper.getSkipCount(this.paginator, event);
            const maxResultCount = this.primengTableHelper.getMaxResultCount(this.paginator, event);

            this.projectsService
                .getAll(null, this.nameFilter, null, null, null, null, null, false, sorting, skipCount, maxResultCount)
                .pipe(
                    takeUntil(this.destroy$),
                    finalize(() => this.primengTableHelper.hideLoadingIndicator()),
                )
                .subscribe((result) => {
                    this.primengTableHelper.totalRecordsCount = result.totalCount;
                    this.primengTableHelper.records = result.items;
                });
        } else {
            setTimeout(() => this.getProjects(event));
        }
    }

    close(): void {
        this.modal.hide();
        this.projectSource.next(null);
    }

    selectProject(project: ProjectListDto): void {
        this.modal.hide();
        this.projectSource.next(project);
    }
}
