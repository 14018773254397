import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[maxTextLength]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxTextLengthDirective, multi: true }],
})
export class MaxTextLengthDirective implements Validator {
    @Input() maxTextLength: number;

    validate(control: AbstractControl): { [key: string]: unknown } {
        return control.value?.length > this.maxTextLength ? { maxlength: { invalid: true, actual: control.value, requiredLength: this.maxTextLength } } : null;
    }
}
