import { Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProjectListDto, ProjectsServiceProxy } from '@shared/service-proxies/service-proxies';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { getProjectId } from '@shared/utils/project.util';

@Component({
    selector: 'app-header-projects-switch',
    templateUrl: './header-projects-switch.component.html',
    styleUrls: ['./header-projects-switch.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderProjectsSwitchComponent extends AppComponentBase implements OnInit, OnDestroy {
    activeProjectId: number;
    isLoading = true;
    projects: ProjectListDto[] = [];

    private readonly destroy$ = new Subject<void>();

    get activeProjectName(): string {
        return this.projects.length ? this.projects.find((project) => project.id === this.activeProjectId).name : '';
    }

    constructor(injector: Injector, private router: Router, private projectsService: ProjectsServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        this.activeProjectId = getProjectId(this.router);
        this.projectsService
            .getAll(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                false,
                undefined,
                undefined,
                this.primengTableHelper.defaultMaxResultCount
            )
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((result) => {
                // Move active project on the first position.
                const projects = result.items;
                const activeProjectIndex = projects.findIndex((project) => project.id === this.activeProjectId);
                [projects[0], projects[activeProjectIndex]] = [projects[activeProjectIndex], projects[0]];
                this.projects = result.items;
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    isActiveProject(project: ProjectListDto): boolean {
        return this.projects.length ? project.id === this.activeProjectId : false;
    }

    logoutProject(event: Event): void {
        event.preventDefault();
        this.router.navigate(['app/projects']);
    }

    private resolveUrl(project: ProjectListDto): string {
        const projectMenuUrls = [
            'tps/configuration-board/control-table/reference-point',
            'tps/configuration-board/control-table/instrument-pillar',
            'tps/configuration-board/points-and-prisms-manager/new-points-prisms-table-view',
            'tps/configuration-board/points-and-prisms-manager/view/table',
            'tps/configuration-board/group-manager',
            'tps/configuration-board/sensor-manager',
            'tps/configuration-board/station-manager/station-list',
            'tps/configuration-board/station-manager/station-activity',
            'tps/configuration-board/station-manager/station-queue',
            'tps/configuration-board/schedule-manager/schedule-overview',
            'tps/configuration-board/schedule-manager/scheduler',
            'tps/configuration-board/schedule-manager/schedule-settings',
            'tps/configuration-board/threshold-profiles/movement-profile',
            'tps/configuration-board/threshold-profiles/measurement-profile',
            'tps/configuration-board/threshold-profiles/instrument-profile',
            'weather-station/configuration-board/sensor-manager',
            'weather-station/configuration-board/station-manager',
            'gnss/configuration-board/sensor-manager',
            'threshold-and-actions/threshold-profiles',
            'threshold-and-actions/action-manager',
            'threshold-and-actions/alarm-check-logs',
            'threshold-and-actions/alarm-notifications',
            'project-log',
            'map-view',
            'weather-dashboards'
        ];
        const projectMenuUrl = projectMenuUrls.find((url) => this.router.url.includes(url));
        const url = projectMenuUrl ? `/app/projects/${project.id}/${projectMenuUrl}` : `/app/projects/${project.id}/${projectMenuUrls[0]}`;
        return url;
    }

    changeProject(project: ProjectListDto): void {
        if (!this.isActiveProject(project)) {
            const url = this.resolveUrl(project);
            window.open(url, '_self');
        }
    }
}
