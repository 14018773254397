/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { Subject, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserPermissionsRefreshService {
    private permissionsChangedSource = new Subject<void>();
    public permissionsChanged$ = this.permissionsChangedSource.asObservable();

    constructor(private router: Router, private httpClient: HttpClient) {}

    public refreshUserPermissions(): void {
        this.httpClient.get<any>(`${AppConsts.remoteServiceBaseUrl}/AbpUserConfiguration/GetAll`).pipe(take(1)).subscribe((res) => {
            const grantedPermissions: { [name: string]: boolean } = res?.result?.auth?.grantedPermissions;
            if (grantedPermissions) {
                const isNoGrantedPermissions = !Object.keys(grantedPermissions).length;
                abp.auth.grantedPermissions = grantedPermissions;
                if (isNoGrantedPermissions) {
                    this.router.navigate(['app/welcome']);
                } else {
                    this.permissionsChangedSource.next();
                }
            }
        });
    }
}
