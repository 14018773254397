<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ 'SelectProject' | localize }}</span>
                </h4>
            </div>
            <div class="modal-body pb-0">
                <form class="form" autocomplete="off" (ngSubmit)="getProjects()">
                    <div class="row align-items-center">
                        <div class="col">
                            <div class="form-group m-form__group align-items-center">
                                <div class="input-group">
                                    <input [(ngModel)]="nameFilter" name="filterText" autoFocus
                                        class="form-control m-input custom-search" [placeholder]="l('SearchWithThreeDot')"
                                        type="text">
                                    <span class="input-group-btn">
                                        <button type="submit" class="btn btn-primary">
                                            <i class="flaticon-search-1"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="primeng-datatable-container mb-2" [busyIf]="primengTableHelper.isLoading">
                    <p-table
                        tableStyleClass="p-table"
                        [value]="primengTableHelper.records"
                        [rows]="primengTableHelper.defaultRecordsCountPerModalPage"
                        [paginator]="false"
                        [lazy]="true"
                        [lazyLoadOnInit]="false"
                        (onLazyLoad)="getProjects($event)"
                        [responsive]="primengTableHelper.isResponsive"
                        [resizableColumns]="primengTableHelper.resizableColumns"
                        pTableWithUnsort>
                        
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    {{ 'Name' | localize }}
                                </th>
                                <th class="w-100px">
                                    {{ 'Actions' | localize }}
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-record="$implicit">
                            <tr class="clickable-table-item" [pSelectableRow]="record">
                                <td>
                                    {{ record.name }}
                                </td>
                                <td class="w-100px">
                                    <button type="button" class="btn btn-sm btn-primary" (click)="selectProject(record)">
                                        {{ 'Select' | localize }}
                                    </button>
                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount === 0">
                        {{ 'NoProjects' | localize }}
                    </div>
                </div>
                <div class="primeng-paging-container position-relative">
                    <span class="total-records-count">
                        {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                    </span>
                    <p-paginator
                        [rows]="primengTableHelper.defaultRecordsCountPerModalPage"
                        [totalRecords]="primengTableHelper.totalRecordsCount"
                        [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                        (onPageChange)="getProjects($event)">
                    </p-paginator>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="close()">
                    {{ 'Close' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
