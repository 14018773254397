import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SessionTimeoutService {
    private readonly isDisableSessionTimeoutSource = new Subject<boolean>();
    isDisableSessionTimeoutSource$ = this.isDisableSessionTimeoutSource.asObservable();

    disableSessionTimeout(): void {
        setTimeout(() => this.isDisableSessionTimeoutSource.next(true));
    }

    enableSessionTimeout(): void {
        setTimeout(() => this.isDisableSessionTimeoutSource.next(false));
    }
}
