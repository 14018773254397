import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[positiveOnly]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PositiveNumberDirective, multi: true }],
})
export class PositiveNumberDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: unknown } {
        return typeof control.value === 'number' && control.value <= 0 ? { positiveOnly: { invalid: true, actual: control.value } } : null;
    }
}
