import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UnitConverterService } from '@app/projects/unit-converter/unit-converter.service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[temperatureConvert]',
})
export class TemperatureConvertDirective implements OnInit, OnDestroy {
    @Input() isTemperatureForView = true;

    private destroy$ = new Subject<void>();

    private get value(): number {
        return this.controlDirective.control.value;
    }

    constructor(private controlDirective: NgControl, private ucs: UnitConverterService) {}

    ngOnInit(): void {
        this.transform();
        this.controlDirective.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter((value) => typeof value === 'number' && this.controlDirective.pristine),
                take(1)
            )
            .subscribe(() => this.transform());
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    private transform(): void {
        this.controlDirective.control.setValue(
            this.isTemperatureForView ? this.ucs.convertTemperatureForView(this.value) : this.ucs.convertTemperatureForEdit(this.value),
            {
                emitEvent: false,
            }
        );
    }

    private destroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
