import { AppComponentBase } from 'shared/common/app-component-base';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal/change-profile-picture-modal.component';
import { ProfileSettingsModalComponent } from '@app/shared/layout/profile/profile-settings-modal/profile-settings-modal.component';

@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppComponentBase implements OnInit {
    @ViewChild('changePasswordModal', { static: true }) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal', { static: true }) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('profileSettingsModal', { static: true }) profileSettingsModal: ProfileSettingsModalComponent;

    isSessionTimeOutEnabled: boolean = this.appSession.userId != null;

    public constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.registerModalOpenEvents();
    }

    registerModalOpenEvents(): void {
        abp.event.on('app.show.changePasswordModal', () => {
            this.changePasswordModal.show();
        });

        abp.event.on('app.show.changeProfilePictureModal', () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on('app.show.profileSettingsModal', () => {
            this.profileSettingsModal.show();
        });
    }

    profileSettingsSaved(): void {
        abp.event.trigger('app.profileSettingsSaved');
    }
}
