import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal/change-profile-picture-modal.component';
import { ProfileSettingsModalComponent } from '@app/shared/layout/profile/profile-settings-modal/profile-settings-modal.component';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './shared/common/app-common.module';
import { SessionTimeoutModalComponent } from './shared/common/session-timeout/session-timeout-modal-component';
import { SessionTimeoutComponent } from './shared/common/session-timeout/session-timeout.component';
import { ScrollTopComponent } from './shared/layout/scroll-top/scroll-top.component';
import { PangeaBrandComponent } from './shared/layout/themes/pangea/pangea-brand/pangea-brand.component';
import { PangeaLayoutComponent } from './shared/layout/themes/pangea/pangea-layout/pangea-layout.component';
import { UserMenuComponent } from './shared/layout/topbar/user-menu.component';
import { HeaderProjectsSwitchComponent } from './shared/layout/header-projects-switch/header-projects-switch.component';
import { AppGuard } from '@app/app.guard';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PangeaSwitchModule } from './shared/common/pangea-switch/pangea-switch.module';
import { HeaderAlarmNotificationsComponent } from './shared/layout/notifications/header-alarm-notifications/header-alarm-notifications.component';
import { HeaderDirective } from './shared/layout/directives/header.directive';
import { MenuDirective } from './shared/layout/directives/menu.directive';
import { OffcanvasDirective } from './shared/layout/directives/offcanvas.directive';
import { ScrollTopDirective } from './shared/layout/directives/scroll-top.directive';
import { ToggleDirective } from './shared/layout/directives/toggle.directive';

// Metronic
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    // suppressScrollX: true
};

@NgModule({
    declarations: [
        AppComponent,
        PangeaLayoutComponent,
        ScrollTopComponent,
        ChangePasswordModalComponent,
        ChangeProfilePictureModalComponent,
        ProfileSettingsModalComponent,
        UserMenuComponent,
        PangeaBrandComponent,
        SessionTimeoutModalComponent,
        SessionTimeoutComponent,
        HeaderProjectsSwitchComponent,
        HeaderAlarmNotificationsComponent,
        MenuDirective,
        OffcanvasDirective,
        HeaderDirective,
        ToggleDirective,
        ScrollTopDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        PrimeNgFileUploadModule,
        ProgressBarModule,
        PerfectScrollbarModule,
        ImageCropperModule,
        AutoCompleteModule,
        MultiSelectModule,
        NgxSpinnerModule,
        AppBsModalModule,
        NgxIntlTelInputModule,
        PangeaSwitchModule,
    ],
    providers: [
        AppGuard,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
})
export class AppModule {}
