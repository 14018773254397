/* eslint-disable no-useless-escape */
import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {
    AngleUnit,
    MeasurementUnitSystem,
    PangeaLengthUnit,
    PressureUnit,
    ProjectDto,
    SpeedUnit,
    TemperatureUnit,
} from '@shared/service-proxies/service-proxies';
import { LocalizationService } from 'abp-ng2-module';
import { isNaN, toNumber } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UnitConverterService {
    private readonly projectSource = new BehaviorSubject<ProjectDto>(new ProjectDto());

    private get project(): ProjectDto {
        return this.projectSource.value;
    }

    public get lengthUnit(): PangeaLengthUnit {
        return this.project.lengthUnit;
    }

    public get angleUnit(): AngleUnit {
        return this.project.angleUnit;
    }

    public get temperatureUnit(): TemperatureUnit {
        return this.project.temperatureUnit;
    }

    public get pressureUnit(): PressureUnit {
        return this.project.pressureUnit;
    }

    public get windSpeedUnit(): SpeedUnit {
        return this.project.windSpeedUnit;
    }

    public get precipitationUnit(): MeasurementUnitSystem {
        return this.project.rainUnit;
    }

    public get hailUnit(): MeasurementUnitSystem {
        return this.project.hailUnit;
    }

    public get coordinatesPrecision(): number {
        return this.project.coordinatesPrecision;
    }

    public get lengthPrecision(): number {
        return this.project.lengthPrecision;
    }

    public get anglePrecision(): number {
        return this.project.anglePrecision;
    }

    public get temperaturePrecision(): number {
        return this.project.temperaturePrecision;
    }

    public get pressurePrecision(): number {
        return this.project.pressurePrecision;
    }

    public get windSpeedPrecision(): number {
        return this.project.windSpeedPrecision;
    }

    public get windDirectionPrecision(): number {
        return this.project.windDirectionPrecision;
    }

    public get precipitationPrecision(): number {
        return this.project.rainPrecision;
    }

    public get hailPrecision(): number {
        return this.project.hailPrecision;
    }

    public get lengthUnitToDisplay(): string {
        switch (this.lengthUnit) {
            case PangeaLengthUnit.Meter:
                return this.localize('MUnit');
            case PangeaLengthUnit.UsSurveyFeet:
                return this.localize('UsFeetUnit');
            case PangeaLengthUnit.InternationalFeet:
                return this.localize('IntFeetUnit');
            default:
                return null;
        }
    }

    public get angleUnitToDisplay(): string {
        switch (this.angleUnit) {
            case AngleUnit.Rad:
                return this.localize('RadUnit');
            case AngleUnit.Degree:
                return this.localize('DecUnit');
            case AngleUnit.Dms:
                return this.localize('DmsUnit');
            case AngleUnit.DecimalDms:
                return this.localize('DecDmsUnit');
            case AngleUnit.Ddm:
                return this.localize('Ddm');
            case AngleUnit.Grad:
                return this.localize('GradUnit');
            case AngleUnit.Gon:
                return this.localize('GonUnit');
            case AngleUnit.DecimalGon:
                return this.localize('DecGonUnit');
            default:
                return null;
        }
    }

    public get temperatureUnitToDisplay(): string {
        switch (this.temperatureUnit) {
            case TemperatureUnit.Celsius:
                return this.localize('CelsiusUnit');
            case TemperatureUnit.Fahrenheit:
                return this.localize('FahrenheitUnit');
            case TemperatureUnit.Kelvin:
                return this.localize('KelvinUnit');
            default:
                return null;
        }
    }

    public get pressureUnitToDisplay(): string {
        switch (this.pressureUnit) {
            case PressureUnit.Bar:
                return this.localize('BarUnit');
            case PressureUnit.MBar:
                return this.localize('MBarUnit');
            case PressureUnit.Pascal:
                return this.localize('PascalUnit');
            case PressureUnit.HPascal:
                return this.localize('HPascalUnit');
            case PressureUnit.MmHg:
                return this.localize('MmHgUnit');
            case PressureUnit.InHg:
                return this.localize('InHgUnit');
            case PressureUnit.Psi:
                return this.localize('PsiUnit');
            case PressureUnit.Torr:
                return this.localize('TorrUnit');
            default:
                return null;
        }
    }

    public get windSpeedUnitToDisplay(): string {
        switch (this.windSpeedUnit) {
            case SpeedUnit.MetersPerSecond:
                return this.localize('MsUnit');
            case SpeedUnit.KilometersPerHour:
                return this.localize('KmhUnit');
            case SpeedUnit.MilesPerHour:
                return this.localize('MphUnit');
            case SpeedUnit.Knots:
                return this.localize('KnotsUnit');
            default:
                return null;
        }
    }

    public get rainAmountUnitToDisplay(): string {
        switch (this.precipitationUnit) {
            case MeasurementUnitSystem.Metric:
                return this.localize('MmUnit');
            case MeasurementUnitSystem.Imperial:
                return this.localize('InchUnit');
            default:
                return null;
        }
    }

    public get rainDurationUnitToDisplay(): string {
        return this.localize('SecUnit');
    }

    public get rainIntensityUnitToDisplay(): string {
        switch (this.precipitationUnit) {
            case MeasurementUnitSystem.Metric:
                return this.localize('MmPerHourUnit');
            case MeasurementUnitSystem.Imperial:
                return this.localize('InchPerHourUnit');
            default:
                return null;
        }
    }

    public get hailAmountUnitToDisplay(): string {
        switch (this.hailUnit) {
            case MeasurementUnitSystem.Metric:
                return this.localize('HitsPerSquareCm');
            case MeasurementUnitSystem.Imperial:
                return this.localize('HitsPerSquareInch');
            default:
                return null;
        }
    }

    public get hailDurationUnitToDisplay(): string {
        return this.localize('SecUnit');
    }

    public get hailIntensityUnitToDisplay(): string {
        switch (this.precipitationUnit) {
            case MeasurementUnitSystem.Metric:
                return this.localize('HitsPerSquareCmHour');
            case MeasurementUnitSystem.Imperial:
                return this.localize('HitsPerSquareInchHour');
            default:
                return null;
        }
    }


    get degreesSymbol(): string {
        return '°';
    }

    get minutesSymbol(): string {
        return '\'';
    }

    get secondsSymbol(): string {
        return '\'\'';
    }

    constructor(private localizationService: LocalizationService) {}

    setProjectData(project: ProjectDto): void {
        this.projectSource.next(project);
    }

    // Length Converter.

    public convertMeterToUsSurveyFeet(value: number): number {
        if (!value && value !== 0) return null;
        return value * 3.2808333333;
    }

    public convertUsSurveyFeetToMeter(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.3048006096;
    }

    public convertMeterToInternationalFeet(value: number): number {
        if (!value && value !== 0) return null;
        return value * 3.280839895;
    }

    public convertInternationalFeetToMeter(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.3048;
    }

    private applyLengthPrecision(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.lengthPrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertLengthToDefault(value: number): number {
        switch (this.lengthUnit) {
            case PangeaLengthUnit.Meter:
                return value;
            case PangeaLengthUnit.UsSurveyFeet:
                return this.convertUsSurveyFeetToMeter(value);
            case PangeaLengthUnit.InternationalFeet:
                return this.convertInternationalFeetToMeter(value);
            default:
                return null;
        }
    }

    public convertLengthForEdit(value: number): number {
        switch (this.lengthUnit) {
            case PangeaLengthUnit.Meter:
                return value;
            case PangeaLengthUnit.UsSurveyFeet:
                return this.convertMeterToUsSurveyFeet(value);
            case PangeaLengthUnit.InternationalFeet:
                return this.convertMeterToInternationalFeet(value);
            default:
                return null;
        }
    }

    public convertLengthForView(value: number): number {
        switch (this.lengthUnit) {
            case PangeaLengthUnit.Meter:
                return this.applyLengthPrecision(value);
            case PangeaLengthUnit.UsSurveyFeet:
                return this.applyLengthPrecision(this.convertMeterToUsSurveyFeet(value));
            case PangeaLengthUnit.InternationalFeet:
                return this.applyLengthPrecision(this.convertMeterToInternationalFeet(value));
            default:
                return null;
        }
    }

    public convertLengthForValidator(value: number): number {
        switch (this.lengthUnit) {
            case PangeaLengthUnit.Meter:
                return value;
            case PangeaLengthUnit.UsSurveyFeet:
                return this.convertMeterToUsSurveyFeet(value);
            case PangeaLengthUnit.InternationalFeet:
                return this.convertMeterToInternationalFeet(value);
            default:
                return null;
        }
    }

    public convertLengthToDefaultForImport(value: number, lengthUnit: PangeaLengthUnit): number {
        switch (lengthUnit) {
            case PangeaLengthUnit.Meter:
                return value;
            case PangeaLengthUnit.UsSurveyFeet:
                return this.convertUsSurveyFeetToMeter(value);
            case PangeaLengthUnit.InternationalFeet:
                return this.convertInternationalFeetToMeter(value);
            default:
                return null;
        }
    }

    public convertLengthFromDefaultToUnit(value: number, lengthUnit: PangeaLengthUnit): number {
        switch (lengthUnit) {
            case PangeaLengthUnit.Meter:
                return value;
            case PangeaLengthUnit.UsSurveyFeet:
                return this.convertMeterToUsSurveyFeet(value);
            case PangeaLengthUnit.InternationalFeet:
                return this.convertMeterToInternationalFeet(value);
            default:
                return null;
        }
    }

    // Coordinates converter

    private applyCoordinatesPrecision(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.coordinatesPrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertCoordinatesToDefault(value: number): number {
        return value;
    }

    public convertCoordinatesForView(value: number): number {
        return this.applyCoordinatesPrecision(value);
    }

    public convertCoordinatesForEdit(value: number): number {
        return value;
    }

    // Angle converter.

    public convertRadToDegree(value: number): number {
        if (!value && value !== 0) return null;
        return (value * 180) / Math.PI;
    }

    public convertDegreeToRad(value: number): number {
        if (!value && value !== 0) return null;
        return (value * Math.PI) / 180;
    }

    public convertRadToDms(value: number, secondsDecimalPlaces?: number): string {
        if (!value && value !== 0) return '';

        const degrees = this.convertRadToDegree(value);
        const minutes = +(degrees % 1).toFixed(10) * 60;
        const seconds = +(minutes % 1).toFixed(10) * 60;

        const d = Math.trunc(degrees);
        const m = Math.trunc(minutes);
        const s = seconds;

        if (typeof secondsDecimalPlaces === 'number') {
            return `${d}${this.degreesSymbol} ${m}${this.minutesSymbol} ${s.toFixed(secondsDecimalPlaces)}${this.secondsSymbol}`;
        } else {
            return `${d}${this.degreesSymbol} ${m}${this.minutesSymbol} ${s}${this.secondsSymbol}`;
        }
    }

    public convertDmsToRad(value: string): number {
        if (!value || typeof value !== 'string') return null;

        const dmsValues = value.split(' ');

        if (dmsValues.length !== 3) return null;

        const isDegreesIncludesChar = /[a-zA-Z]/.test(dmsValues[0]);
        const isMinutesIncludesChar = /[a-zA-Z]/.test(dmsValues[1]);
        const isSecondsIncludesChar = /[a-zA-Z]/.test(dmsValues[2]);

        if (isDegreesIncludesChar || isMinutesIncludesChar || isSecondsIncludesChar) {
            return null;
        }

        const degreeString = dmsValues[0].replace(/[^0-9\.]+/g, '');
        const minuteString = dmsValues[1].replace(/[^0-9\.]+/g, '');
        const secondString = dmsValues[2].replace(/[^0-9\.]+/g, '');

        if (!degreeString || !minuteString || !secondString) {
            return null;
        }

        const d = Number(degreeString);
        const m = Number(minuteString);
        const s = Number(secondString);

        const degrees = d + m / 60 + s / 3600;
        return this.convertDegreeToRad(degrees);
    }

    public convertRadToDdm(value: number, minutesDecimalPlaces?: number): string {
        if (!value && value !== 0) return '';
        if (value === 0) return '0° 0.0000\'';

        const degrees = this.convertRadToDegree(value);
        const minutes = (degrees % 1) * 60;

        const d = Math.trunc(degrees);
        const m = +minutes;

        if (typeof minutesDecimalPlaces === 'number') {
            return `${d}${this.degreesSymbol} ${m.toFixed(minutesDecimalPlaces)}${this.minutesSymbol}`;
        } else {
            return `${d}${this.degreesSymbol} ${m}${this.minutesSymbol}`;
        }
    }

    public convertDdmToRad(value: string): number {
        if (!value || typeof value !== 'string') return null;

        const ddmValues = value.split(' ');

        if (ddmValues.length !== 2) return null;

        const isDegreesIncludesChar = /[a-zA-Z]/.test(ddmValues[0]);
        const isMinutesIncludesChar = /[a-zA-Z]/.test(ddmValues[1]);

        if (isDegreesIncludesChar || isMinutesIncludesChar) {
            return null;
        }

        const degreeString = ddmValues[0].replace(/[^0-9\.]+/g, '');
        const minuteString = ddmValues[1].replace(/[^0-9\.]+/g, '');

        if (!degreeString || !minuteString) {
            return null;
        }

        const d = Number(degreeString);
        const m = Number(minuteString);

        const degrees = d + m / 60;
        return this.convertDegreeToRad(degrees);
    }

    public convertRadToDecimalDms(value: number): number {
        if (!value && value !== 0) return null;

        const degrees = this.convertRadToDegree(value);
        const minutes = (degrees % 1) * 60;

        const d = Math.trunc(degrees);
        const m = minutes / 100;

        return d + m;
    }

    public convertRadToDecimalDmsWithPrecision(value: number): number {
        return this.applyAnglePrecisionWithoutRound(this.convertRadToDecimalDms(value));
    }

    public convertDecimalDmsToRad(value: number): number {
        if ((!value && value !== 0) || (isNaN(value) && value !== 0)) return null;
        const dmsValues = value.toString().split('.');
        if (dmsValues.length === 1) {
            return this.convertDegreeToRad(+dmsValues[0]);
        } else if (dmsValues.length === 2) {
            const d = +dmsValues[0];
            const m = +`0.${dmsValues[1]}`;
            const degrees = d + (m / 60) * 100;
            return this.convertDegreeToRad(degrees);
        } else {
            return null;
        }
    }

    public convertRadToGrad(value: number): number {
        if (!value && value !== 0) return null;
        return (value * 200) / Math.PI;
    }

    public convertGradToRad(value: number): number {
        if (!value && value !== 0) return null;
        return (value * Math.PI) / 200;
    }

    public convertRadToGon(value: number): number {
        if (!value && value !== 0) return null;
        return (value * 200) / Math.PI;
    }

    public convertGonToRad(value: number): number {
        if (!value && value !== 0) return null;
        return (value * Math.PI) / 200;
    }

    private applyAnglePrecisionWithoutRound(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = this.numberToFixedWithoutRound(value, this.anglePrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    private applyAnglePrecisionWithRound(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.anglePrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertAngleToDefault(value: number | string): number {
        switch (this.angleUnit) {
            case AngleUnit.Rad:
                return <number>value;
            case AngleUnit.Degree:
                return this.convertDegreeToRad(<number>value);
            case AngleUnit.Dms:
                return this.convertDmsToRad(<string>value);
            case AngleUnit.DecimalDms:
                return this.convertDecimalDmsToRad(<number>value);
            case AngleUnit.Grad:
                return this.convertGradToRad(<number>value);
            case AngleUnit.Gon:
                return this.convertGonToRad(<number>value);
            case AngleUnit.Ddm:
                return this.convertDdmToRad(<string>value);
            default:
                return null;
        }
    }

    public convertAngleForView(value: number): number | string {
        switch (this.angleUnit) {
            case AngleUnit.Rad:
                return this.applyAnglePrecisionWithoutRound(value);
            case AngleUnit.Degree:
                return this.applyAnglePrecisionWithoutRound(this.convertRadToDegree(value));
            case AngleUnit.Dms:
                return this.convertRadToDms(value, 2);
            case AngleUnit.DecimalDms:
                return this.applyAnglePrecisionWithoutRound(this.convertRadToDecimalDms(value));
            case AngleUnit.Grad:
                return this.applyAnglePrecisionWithoutRound(this.convertRadToGrad(value));
            case AngleUnit.Gon:
                return this.applyAnglePrecisionWithoutRound(this.convertRadToGon(value));
            case AngleUnit.Ddm:
                return this.convertRadToDdm(value, 2);
            default:
                return null;
        }
    }

    public convertAngleForEdit(value: number): number | string {
        switch (this.angleUnit) {
            case AngleUnit.Rad:
                return value;
            case AngleUnit.Degree:
                return this.convertRadToDegree(value);
            case AngleUnit.Dms:
                return this.convertRadToDms(value);
            case AngleUnit.DecimalDms:
                return this.convertRadToDecimalDms(value);
            case AngleUnit.Grad:
                return this.convertRadToGrad(value);
            case AngleUnit.Gon:
                return this.convertRadToGon(value);
            case AngleUnit.Ddm:
                return this.convertRadToDdm(value);
            default:
                return null;
        }
    }

    public convertAngleForValidator(value: number): number | string {
        switch (this.angleUnit) {
            case AngleUnit.Rad:
                return value;
            case AngleUnit.Degree:
                return this.convertRadToDegree(value);
            case AngleUnit.Dms:
                return this.convertRadToDms(value);
            case AngleUnit.DecimalDms:
                return this.convertRadToDecimalDms(value);
            case AngleUnit.Grad:
                return this.convertRadToGrad(value);
            case AngleUnit.Gon:
                return this.convertRadToGon(value);
            case AngleUnit.Ddm:
                return this.convertRadToDdm(value);
            default:
                return null;
        }
    }

    public convertAngleToDefaultForImport(value: string, angleUnit: AngleUnit): number {
        switch (angleUnit) {
            case AngleUnit.Rad:
                return this.parseNumber(value);
            case AngleUnit.Degree:
                return this.convertDegreeToRad(this.parseNumber(value));
            case AngleUnit.Dms:
                return this.convertDmsToRad(value);
            case AngleUnit.DecimalDms:
                return this.convertDecimalDmsToRad(this.parseNumber(value));
            case AngleUnit.Grad:
                return this.convertGradToRad(this.parseNumber(value));
            case AngleUnit.Gon:
                return this.convertGonToRad(this.parseNumber(value));
            case AngleUnit.Ddm:
                return this.convertDdmToRad(value);
            default:
                return null;
        }
    }

    // Temperature converter.

    public convertCelsiusToFahrenheit(value: number): number {
        if (!value && value !== 0) return null;
        return (value * 9) / 5 + 32;
    }

    public convertFahrenheitToCelsius(value: number): number {
        if (!value && value !== 0) return null;
        return ((value - 32) * 5) / 9;
    }

    public convertCelsiusToKelvin(value: number): number {
        if (!value && value !== 0) return null;
        return value + 273.15;
    }

    public convertKelvinToCelsius(value: number): number {
        if (!value && value !== 0) return null;
        return value - 273.15;
    }

    private applyTemperaturePrecision(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.temperaturePrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertTemperatureToDefault(value: number): number {
        switch (this.temperatureUnit) {
            case TemperatureUnit.Celsius:
                return value;
            case TemperatureUnit.Fahrenheit:
                return this.convertFahrenheitToCelsius(value);
            case TemperatureUnit.Kelvin:
                return this.convertKelvinToCelsius(value);
            default:
                return null;
        }
    }

    public convertTemperatureForView(value: number): number {
        switch (this.temperatureUnit) {
            case TemperatureUnit.Celsius:
                return this.applyTemperaturePrecision(value);
            case TemperatureUnit.Fahrenheit:
                return this.applyTemperaturePrecision(this.convertCelsiusToFahrenheit(value));
            case TemperatureUnit.Kelvin:
                return this.applyTemperaturePrecision(this.convertCelsiusToKelvin(value));
            default:
                return null;
        }
    }

    public convertTemperatureForEdit(value: number): number {
        switch (this.temperatureUnit) {
            case TemperatureUnit.Celsius:
                return value;
            case TemperatureUnit.Fahrenheit:
                return this.convertCelsiusToFahrenheit(value);
            case TemperatureUnit.Kelvin:
                return this.convertCelsiusToKelvin(value);
            default:
                return null;
        }
    }

    public convertTemperatureForValidator(value: number): number {
        switch (this.temperatureUnit) {
            case TemperatureUnit.Celsius:
                return value;
            case TemperatureUnit.Fahrenheit:
                return this.convertCelsiusToFahrenheit(value);
            case TemperatureUnit.Kelvin:
                return this.convertCelsiusToKelvin(value);
            default:
                return null;
        }
    }

    // Pressure converter.

    public convertMBarToPascal(value: number): number {
        if (!value && value !== 0) return null;
        return value * 100;
    }

    public convertPascalToMBar(value: number): number {
        if (!value && value !== 0) return null;
        return value / 100;
    }

    public convertMBarToHPascal(value: number): number {
        if (!value && value !== 0) return null;
        return value * 10;
    }

    public convertHPascalToMBar(value: number): number {
        if (!value && value !== 0) return null;
        return value / 10;
    }

    public convertMBarToPsi(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.0145037738;
    }

    public convertPsiToMBar(value: number): number {
        if (!value && value !== 0) return null;
        return value / 0.0145037738;
    }

    public convertMBarToTorr(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.7500616827;
    }

    public convertTorrToMBar(value: number): number {
        if (!value && value !== 0) return null;
        return value / 0.7500616827;
    }

    public convertMBarToHpa(value: number): number {
        if (!value && value !== 0) return null;
        return value;
    }

    public convertHpaToMBar(value: number): number {
        if (!value && value !== 0) return null;
        return value;
    }

    public convertMBarToBar(value: number): number {
        if (!value && value !== 0) return null;
        return value / 1000;
    }

    public convertBarToMBar(value: number): number {
        if (!value && value !== 0) return null;
        return value * 1000;
    }

    public convertMBarToMmHg(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.7500616827;
    }

    public convertMmHgToMBar(value: number): number {
        if (!value && value !== 0) return null;
        return value / 0.7500616827;
    }

    public convertMBarToInHg(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.0295299831;
    }

    public convertInHgToMBar(value: number): number {
        if (!value && value !== 0) return null;
        return value / 0.0295299831;
    }

    private applyPressurePrecision(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.pressurePrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertPressureToDefault(value: number): number {
        switch (this.pressureUnit) {
            case PressureUnit.Bar:
                return this.convertBarToMBar(value);
            case PressureUnit.MBar:
                return value;
            case PressureUnit.Pascal:
                return this.convertPascalToMBar(value);
            case PressureUnit.HPascal:
                return this.convertHPascalToMBar(value);
            case PressureUnit.MmHg:
                return this.convertMmHgToMBar(value);
            case PressureUnit.InHg:
                return this.convertInHgToMBar(value);
            case PressureUnit.Psi:
                return this.convertPsiToMBar(value);
            case PressureUnit.Torr:
                return this.convertTorrToMBar(value);
            default:
                return null;
        }
    }

    public convertPressureForView(value: number): number {
        switch (this.pressureUnit) {
            case PressureUnit.Bar:
                return this.applyPressurePrecision(this.convertMBarToBar(value));
            case PressureUnit.MBar:
                return this.applyPressurePrecision(value);
            case PressureUnit.Pascal:
                return this.applyPressurePrecision(this.convertMBarToPascal(value));
            case PressureUnit.HPascal:
                return this.applyPressurePrecision(this.convertMBarToHPascal(value));
            case PressureUnit.MmHg:
                return this.applyPressurePrecision(this.convertMBarToMmHg(value));
            case PressureUnit.InHg:
                return this.applyPressurePrecision(this.convertMBarToInHg(value));
            case PressureUnit.Psi:
                return this.applyPressurePrecision(this.convertMBarToPsi(value));
            case PressureUnit.Torr:
                return this.applyPressurePrecision(this.convertMBarToTorr(value));
            default:
                return null;
        }
    }

    public convertPressureForEdit(value: number): number {
        switch (this.pressureUnit) {
            case PressureUnit.Bar:
                return this.convertMBarToBar(value);
            case PressureUnit.MBar:
                return value;
            case PressureUnit.Pascal:
                return this.convertMBarToPascal(value);
            case PressureUnit.HPascal:
                return this.convertMBarToHPascal(value);
            case PressureUnit.MmHg:
                return this.convertMBarToMmHg(value);
            case PressureUnit.InHg:
                return this.convertMBarToInHg(value);
            case PressureUnit.Psi:
                return this.convertMBarToPsi(value);
            case PressureUnit.Torr:
                return this.convertMBarToTorr(value);
            default:
                return null;
        }
    }

    public convertPressureForValidator(value: number): number {
        switch (this.pressureUnit) {
            case PressureUnit.MBar:
                return value;
            case PressureUnit.Pascal:
                return this.convertMBarToPascal(value);
            case PressureUnit.Psi:
                return this.convertPsiToMBar(value);
            case PressureUnit.Torr:
                return this.convertTorrToMBar(value);
            default:
                return null;
        }
    }

    // Wind speed converter.

    public convertMsToKmh(value: number): number {
        if (!value && value !== 0) return null;
        return value * 3.6;
    }

    public convertMsToMph(value: number): number {
        if (!value && value !== 0) return null;
        return value * 2.2369362921;
    }

    public convertMsToKnots(value: number): number {
        if (!value && value !== 0) return null;
        return value * 1.9438444924;
    }

    private applyWindSpeedPrecision(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.windSpeedPrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertWindSpeedForView(value: number): number {
        switch (this.windSpeedUnit) {
            case SpeedUnit.MetersPerSecond:
                return this.applyWindSpeedPrecision(value);
            case SpeedUnit.KilometersPerHour:
                return this.applyWindSpeedPrecision(this.convertMsToKmh(value));
            case SpeedUnit.MilesPerHour:
                return this.applyWindSpeedPrecision(this.convertMsToMph(value));
            case SpeedUnit.Knots:
                return this.applyWindSpeedPrecision(this.convertMsToKnots(value));
            default:
                return null;
        }
    }

    // Wind direction converter.

    private applyWindDirectionPrecision(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.windDirectionPrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertWindDirectionForView(value: number): number {
        return this.applyWindDirectionPrecision(this.convertRadToDegree(value));
    }

    // Precipitation converter.

    public convertMmToInch(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.0393700787;
    }

    public convertMmPerHourToInchPerHour(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.0393700787;
    }

    private applyPrecipitationPrecision(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.precipitationPrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertRainAmountForView(value: number): number {
        switch (this.precipitationUnit) {
            case MeasurementUnitSystem.Metric:
                return this.applyPrecipitationPrecision(value);
            case MeasurementUnitSystem.Imperial:
                return this.applyPrecipitationPrecision(this.convertMmToInch(value));
            default:
                return null;
        }
    }

    public convertRainIntensityForView(value: number): number {
        switch (this.precipitationUnit) {
            case MeasurementUnitSystem.Metric:
                return this.applyPrecipitationPrecision(value);
            case MeasurementUnitSystem.Imperial:
                return this.applyPrecipitationPrecision(this.convertMmPerHourToInchPerHour(value));
            default:
                return null;
        }
    }

    // Hail converter.

    public convertHitsPerSquareMeterToHitsPerSquareInch(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.00064516;
    }

    public convertHitsPerSquareMeterHourToHitsPerSquareInchHour(value: number): number {
        if (!value && value !== 0) return null;
        return value * 0.00064516;
    }

    private applyHailPrecision(value: number): number {
        if (!value && value !== 0) return null;
        const convertedValue = +value.toFixed(this.hailPrecision);
        return convertedValue !== 0 ? convertedValue : value;
    }

    public convertHailAmountForView(value: number): number {
        switch (this.hailUnit) {
            case MeasurementUnitSystem.Metric:
                return this.applyHailPrecision(value);
            case MeasurementUnitSystem.Imperial:
                return this.applyHailPrecision(this.convertHitsPerSquareMeterToHitsPerSquareInch(value));
            default:
                return null;
        }
    }

    public convertHailIntensityForView(value: number): number {
        switch (this.hailUnit) {
            case MeasurementUnitSystem.Metric:
                return this.applyHailPrecision(value);
            case MeasurementUnitSystem.Imperial:
                return this.applyHailPrecision(this.convertHitsPerSquareMeterHourToHitsPerSquareInchHour(value));
            default:
                return null;
        }
    }

    private parseNumber(value: unknown): number | undefined {
        if (typeof value === 'string') {
            if (value.replace(/\s/g, '') === '') return undefined;
            const number = toNumber(value);
            return !isNaN(number) && isFinite(number) ? number : undefined;
        } else if (typeof value === 'number') {
            return value;
        } else {
            return undefined;
        }
    }

    private addLeadingZeros(number: number, totalLength: number): string {
        return String(number).padStart(totalLength, '0');
    }

    private numberToFixedWithoutRound(number: number, precision: number): number {
        const factor = Math.pow(10, precision);
        return Math.floor(number * factor) / factor;
    }

    private localize(value: string): string {
        return this.localizationService.localize(value, AppConsts.localization.defaultLocalizationSourceName);
    }
}
