import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UnitConverterService } from '@app/projects/unit-converter/unit-converter.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[coordinatesConvert]',
})
export class CoordinatesConvertDirective implements OnInit, OnDestroy {
    @Input() isCoordinatesForView = true;

    private destroy$ = new Subject<void>();

    private get value(): number {
        return this.elementRef.nativeElement.value ? +this.elementRef.nativeElement.value : null;
    }

    constructor(private controlDirective: NgControl, private elementRef: ElementRef, private ucs: UnitConverterService) {}

    ngOnInit(): void {
        this.transform();
        this.controlDirective.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter(() => this.controlDirective.pristine)
            )
            .subscribe(() => this.transform());
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    private transform(): void {
        this.elementRef.nativeElement.value = this.isCoordinatesForView
            ? this.ucs.convertCoordinatesForView(this.value)
            : this.ucs.convertCoordinatesForEdit(this.value);
    }

    private destroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
