<div class="d-flex flex-column flex-root">
    <app-pangea-layout></app-pangea-layout>
</div>

<kt-scroll-top></kt-scroll-top>

<app-change-password-modal #changePasswordModal></app-change-password-modal>
<app-change-profile-picture-modal #changeProfilePictureModal></app-change-profile-picture-modal>
<app-profile-settings-modal #profileSettingsModal (modalSave)="profileSettingsSaved()"></app-profile-settings-modal>

<session-timeout #sessionTimeout *ngIf="isSessionTimeOutEnabled"></session-timeout>
