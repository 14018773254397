import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[dynamicDropdownPosition]',
})
export class DynamicDropdownPositionDirective implements AfterViewInit {
    @Input() buttonHeightPx = 32;

    private readonly additionalOffsetPxX = 20;
    private readonly additionalOffsetPxY = 2;

    constructor(private element: ElementRef) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            const dropdown: HTMLUListElement = this.element.nativeElement;
            const dropdownRect = dropdown.getBoundingClientRect();

            const isDropdownWithinWindowRightSide = window.innerWidth >= dropdownRect.right;
            const isDropdownWithinWindowBottomSide = window.innerHeight >= dropdownRect.bottom;

            const offsetX = !isDropdownWithinWindowRightSide ? -(dropdownRect.right - window.innerWidth + this.additionalOffsetPxX) : 0;
            const offsetY = !isDropdownWithinWindowBottomSide ? -(dropdownRect.height + this.buttonHeightPx + this.additionalOffsetPxY) : 0;

            dropdown.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0px)`;
        });
    }
}
