<div appBsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'ChangeProfilePicture' | localize }}</h5>
            </div>
            <div class="modal-body">
                <span class="mb-5 d-block">{{ 'PictureChangeInfo' | localize: maxPictureMbValue }}</span>
                <div class="d-flex flex-row">
                    <img src="assets/common/images/empty-picture.png" alt="Empty picture" *ngIf="!isProfilePictureSet">
                    <image-cropper
                        *ngIf="isProfilePictureSet"
                        [imageBase64]="profilePictureSrc"
                        [imageFile]="imageFile"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 4"
                        [resizeToWidth]="128"
                        (imageCropped)="imageCropped($event)"
                    ></image-cropper>
                    <div class="profile-image-buttons">
                        <button type="button" class="btn btn-outline-primary" (click)="file.click()" >
                            {{ 'SelectImage' | localize }}
                        </button>
                        <button type="button" class="btn btn-outline-primary" *ngIf="isProfilePictureSet" (click)="clearProfileImage()">
                            {{ 'ClearProfilePicture' | localize }}
                        </button>
                        <input #file type="file" accept=".jpg,.jpeg,.png" hidden (change)="fileChanged($event)">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="close()">{{ 'Cancel' | localize }}</button>
                <button type="button" class="btn btn-primary" [disabled]="!croppedImage || saving" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')" (click)="save()">
                    <i class="fa fa-save"></i>
                    <span>{{ 'Save' | localize }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
