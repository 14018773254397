import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AmasiaDatabaseStatusService } from './amasia-database-status.service';

@Injectable({
    providedIn: 'root',
})
export class AmasiaDatabaseStatusInterceptorService implements HttpInterceptor {
    private readonly amasiaDatabaseIncompatibleMessage = 'AMASIA DB is incompatible';

    constructor(private amasiaDatabaseStatusService: AmasiaDatabaseStatusService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            tap({
                error: (resp: HttpErrorResponse) => {
                    if (resp && resp.status === 400 && resp.error instanceof Blob) {
                        const blob = resp.error as Blob;
                        if (blob.type === 'application/json') {
                            const reader = new FileReader();
                            reader.addEventListener('loadend', () => {
                                try {
                                    const json = JSON.parse(reader.result as string);
                                    if (json && json.error?.message && json.error.message.includes(this.amasiaDatabaseIncompatibleMessage)) {
                                        this.amasiaDatabaseStatusService.isAmasiaDatabaseIncompatible = true;
                                    }
                                    // eslint-disable-next-line no-empty
                                } catch (error) {}
                                reader.removeAllListeners();
                            });
                            reader.readAsText(blob);
                        }
                    }
                },
            })
        );
    }
}
