import { Component, Injector, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SessionTimeoutModalComponent } from './session-timeout-modal-component';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { LocalStorageService } from '@shared/utils/services/local-storage.service';
import { SessionTimeoutService } from './session-timeout.service';
import { setInterval, clearInterval } from 'worker-timers';

@Component({
    selector: 'session-timeout',
    template: '<session-timeout-modal></session-timeout-modal>',
})
export class SessionTimeoutComponent extends AppComponentBase implements AfterViewInit, OnDestroy {
    @ViewChild(SessionTimeoutModalComponent)
    private sessionTimeOutModal: SessionTimeoutModalComponent;

    private destroy$ = new Subject<void>();

    private timeOutSecond = parseInt(this.s('App.UserManagement.SessionTimeOut.TimeOutSecond')); // show inactivity modal when TimeOutSecond passed
    private lastActivityTimeStorageKey = 'SessionTimeOut.UserLastActivityTime';

    private IsUserActive = true;
    private notifierIsOpened = false;
    private isDisableSessionTimeout = false;
    private mainInterval: number;
    private subscriptions: Subscription[] = [];

    constructor(injector: Injector, private localStorageService: LocalStorageService, private sessionTimeoutService: SessionTimeoutService) {
        super(injector);
    }

    ngAfterViewInit(): void {
        this.bindActions();
        this.writeToStorage(); // initialize store
        this.mainInterval = setInterval(() => {
            if (!this.isDisableSessionTimeout) {
                this.control();
            }
        }, 1000);
        this.subscriptions.push(
            this.sessionTimeoutService.isDisableSessionTimeoutSource$.subscribe(
                (isDisableSessionTimeout) => (this.isDisableSessionTimeout = isDisableSessionTimeout)
            )
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        clearInterval(this.mainInterval);
    }

    private bindActions(): void {
        this.subscriptions.push(
            fromEvent(window, 'mousemove')
                .pipe(takeUntil(this.destroy$), debounceTime(350))
                .subscribe(() => {
                    this.setUserActivity();
                })
        );

        this.subscriptions.push(
            fromEvent(window, 'mousedown')
                .pipe(takeUntil(this.destroy$), debounceTime(350))
                .subscribe(() => {
                    this.setUserActivity();
                })
        );

        this.subscriptions.push(
            fromEvent(window, 'click')
                .pipe(takeUntil(this.destroy$), debounceTime(350))
                .subscribe(() => {
                    this.setUserActivity();
                })
        );

        this.subscriptions.push(
            fromEvent(window, 'scroll')
                .pipe(takeUntil(this.destroy$), debounceTime(350))
                .subscribe(() => {
                    this.setUserActivity();
                })
        );

        this.subscriptions.push(
            fromEvent(window, 'keypress')
                .pipe(takeUntil(this.destroy$), debounceTime(350))
                .subscribe(() => {
                    this.setUserActivity();
                })
        );
    }

    private setUserActivity(): void {
        this.IsUserActive = true;
    }

    private control(): void {
        this.writeToStorage();
        this.controlStorage();
    }

    private writeToStorage(): void {
        if (this.IsUserActive) {
            if (localStorage) {
                this.localStorageService.setItem(this.lastActivityTimeStorageKey, Date.now().toString());
            } else {
                abp.utils.setCookieValue(this.lastActivityTimeStorageKey, Date.now().toString());
            }
        }
        this.IsUserActive = false;
    }

    private controlStorage(): void {
        if (localStorage) {
            this.localStorageService.getItem(this.lastActivityTimeStorageKey, (err: unknown, data: string) => {
                if (data) {
                    const lastActivityTime = parseInt(data);
                    this.controlValue(lastActivityTime);
                }
            });
        } else {
            const lastActivityTime = parseInt(abp.utils.getCookieValue(this.lastActivityTimeStorageKey));
            this.controlValue(lastActivityTime);
        }
    }
    private controlValue(lastActivityTime: number): void {
        if (Date.now() - lastActivityTime <= this.timeOutSecond * 1000) {
            if (this.notifierIsOpened) {
                this.sessionTimeOutModal.stop();
                this.notifierIsOpened = false;
            }
            return;
        }

        if (!this.notifierIsOpened) {
            this.sessionTimeOutModal.start();
            this.notifierIsOpened = true;
        }
    }
}
