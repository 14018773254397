import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getProjectId } from '@shared/utils/project.util';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ThresholdProfileAlarmNotificationsServiceProxy } from '@shared/service-proxies/service-proxies';
import { AlarmNotificationsSignalRService } from '../services/alarm-notifications-signalr.service';

@Component({
    selector: 'app-header-alarm-notifications',
    templateUrl: './header-alarm-notifications.component.html',
    styleUrls: ['./header-alarm-notifications.component.scss'],
})
export class HeaderAlarmNotificationsComponent implements OnInit, OnDestroy {
    projectId: number;
    notificationsCount: number;
    isLoading = false;

    private destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private thresholdProfileAlarmNotificationsService: ThresholdProfileAlarmNotificationsServiceProxy,
        private alarmNotificationsSignalRService: AlarmNotificationsSignalRService
    ) {}

    ngOnInit(): void {
        this.projectId = getProjectId(this.router);
        this.loadNotificationsCount();
        this.handleNotificationsCountChanged();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private loadNotificationsCount(): void {
        this.isLoading = true;
        this.thresholdProfileAlarmNotificationsService
            .getNotAcknowledgedCount(this.projectId)
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((count) => (this.notificationsCount = count));
    }

    private handleNotificationsCountChanged(): void {
        this.alarmNotificationsSignalRService.notificationsCountChanged$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            if (data.projectId === this.projectId) {
                this.notificationsCount = data.count;
            }
        });
    }
}
