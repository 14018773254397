import { PermissionCheckerService, RefreshTokenService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Data, Route, Router } from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Observable } from 'rxjs/internal/Observable';
import { of, Subject } from 'rxjs';
import { PermissionsConsts } from '@shared/PermissionsConsts';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private permissionChecker: PermissionCheckerService,
        private router: Router,
        private sessionService: AppSessionService,
        private refreshTokenService: RefreshTokenService
    ) {}

    canActivateInternal(data: Data): Observable<boolean> {
        if (!this.sessionService.user) {
            const sessionObservable = new Subject<boolean>();

            this.refreshTokenService.tryAuthWithRefreshToken().subscribe(
                (authResult: boolean) => {
                    if (authResult) {
                        sessionObservable.next(true);
                        sessionObservable.complete();
                        location.reload();
                    } else {
                        sessionObservable.next(false);
                        sessionObservable.complete();
                        this.router.navigate(['/account/login']);
                    }
                },
                () => {
                    sessionObservable.next(false);
                    sessionObservable.complete();
                    this.router.navigate(['/account/login']);
                }
            );
            return sessionObservable;
        }

        if (!data || !data.permission) {
            return of(true);
        }

        if (!Array.isArray(data.permission) ? this.isGranted(data.permission) : this.isGrantedAny(data.permission)) {
            return of(true);
        }

        this.router.navigate([this.selectBestRoute()]);
        return of(false);
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data);
    }

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.canActivate(route);
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data);
    }

    selectBestRoute(): string {
        if (!this.sessionService.user) {
            return '/account/login';
        }
        if (this.permissionChecker.isGranted(PermissionsConsts.Pages_Projects)) {
            return '/app/projects';
        }
        if (this.permissionChecker.isGranted(PermissionsConsts.Pages_UniversalSettings)) {
            return 'app/universal-settings';
        }
        if (this.permissionChecker.isGranted(PermissionsConsts.Pages_Licenses)) {
            return 'app/license-manager/overview';
        }
        if (this.permissionChecker.isGranted(PermissionsConsts.Pages_RolesAndUsers)) {
            return 'app/admin/roles-and-users';
        }
        return '/app/welcome';
    }

    private isGranted(permissionName: string): boolean {
        return this.permissionChecker.isGranted(permissionName);
    }

    private isGrantedAny(permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }
}
