import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PermissionCheckerService } from '@node_modules/abp-ng2-module';
import { PermissionsConsts } from '@shared/PermissionsConsts';

@Injectable()
export class AppGuard implements CanActivate {
    constructor(private router: Router, private permissionChecker: PermissionCheckerService) {}

    canActivate(data: ActivatedRouteSnapshot): boolean {
        if (this.permissionChecker.isGranted(data.data.permission) && data.data.permission === PermissionsConsts.Pages) {
            if (this.permissionChecker.isGranted(PermissionsConsts.Pages_Projects)) {
                this.router.navigate(['app/projects']);
            } else if (this.permissionChecker.isGranted(PermissionsConsts.Pages_UniversalSettings)) {
                this.router.navigate(['app/universal-settings']);
            } else if (this.permissionChecker.isGranted('Pages.Licenses')) {
                this.router.navigate(['app/license-manager/overview']);
            } else if (this.permissionChecker.isGranted(PermissionsConsts.Pages_RolesAndUsers)) {
                this.router.navigate(['app/roles-and-users']);
            }
        } else {
            this.router.navigate(['app/welcome']);
        }
        return this.permissionChecker.isGranted(data.data.permission);
    }
}
