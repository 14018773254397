import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToString'
})
export class TimeToStringPipe implements PipeTransform {
  transform(value: string): string {
      if (!value) return value;

      const parts = value.split(':');
      let hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = Math.floor(parseFloat(parts[2]));

      const days = Math.floor(hours / 24);
      hours = hours % 24;

      let formattedTime = '';
      if (days > 0) {
          formattedTime += `${days}d `;
      }
      if (hours > 0 || days > 0) {
          formattedTime += `${hours}h `;
      }
      if (minutes > 0 || hours > 0 || days > 0) {
          formattedTime += `${minutes}m `;
      }
      formattedTime += `${seconds}s`;

      return formattedTime.trim();
  }
}
