import { Pipe, PipeTransform } from '@angular/core';
import { UnitConverterService } from '../unit-converter.service';

@Pipe({
    name: 'lengthConvert',
})
export class LengthConvertPipe implements PipeTransform {
    constructor(private ucs: UnitConverterService) {}

    transform(value: number): string | null {
        return this.ucs.convertLengthForView(value)?.toString();
    }
}
