import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'projectDuration',
})
export class ProjectDurationPipe implements PipeTransform {
    transform(value: number): string {
        const secNow = value / 10000000;
        const h = Math.floor(secNow / 3600);
        const m = Math.floor((secNow % 3600) / 60);
        return `${h}h ${m}m`;
    }
}
