import { Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LocalStorageService } from '@shared/utils/services/local-storage.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

const LAST_VISITED_ROUTE_KEY = 'LastVisitedRoute';
const LAST_VISITED_PROJECT_ROUTE_KEY = 'LastVisitedProjectRoute';

type UserLastVisitedRoute = {
    userId: number;
    url: string;
};

@Injectable()
export class GlobalRouteChangeHandlerService {
    private router: Router;
    private localStorageService: LocalStorageService;

    private _userId: number;

    private get userId(): number {
        return this._userId ?? abp.session.userId;
    }

    get lastVisitedRoute(): Observable<string> {
        return new Observable((observer) => {
            this.localStorageService.getItem(LAST_VISITED_ROUTE_KEY, (_error: unknown, value: UserLastVisitedRoute[]) => {
                const url = this.getLastVisitedUserRouteFromLocalStorageValue(Array.isArray(value) ? value : []);
                observer.next(url);
            });
        });
    }

    get lastVisitedProjectRoute(): Observable<string> {
        return new Observable((observer) => {
            this.localStorageService.getItem(LAST_VISITED_PROJECT_ROUTE_KEY, (_error: unknown, value: UserLastVisitedRoute[]) => {
                const url = this.getLastVisitedUserRouteFromLocalStorageValue(Array.isArray(value) ? value : []);
                observer.next(url);
            });
        });
    }

    constructor(private injector: Injector) {
        this.router = this.injector.get(Router);
        this.localStorageService = this.injector.get(LocalStorageService);
    }

    init(): void {
        this.router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.url)
            )
            .subscribe((url) => {
                if (url !== '/' && !url.startsWith('/account')) {
                    this.saveLastVisitedRouteToLocalStorage(LAST_VISITED_ROUTE_KEY, { userId: this.userId, url });
                }
                if (
                    url.match(/\/app\/projects\/\d+\/tps\//) ||
                    url.match(/\/app\/projects\/\d+\/weather-station\//) ||
                    url.match(/\/app\/projects\/\d+\/gnss\//)
                ) {
                    this.saveLastVisitedRouteToLocalStorage(LAST_VISITED_PROJECT_ROUTE_KEY, { userId: this.userId, url });
                }
            });
    }

    setUserId(value: number): void {
        this._userId = value;
    }

    private saveLastVisitedRouteToLocalStorage(key: string, data: UserLastVisitedRoute): void {
        this.localStorageService.getItem(key, (_error: unknown, value: UserLastVisitedRoute[]) => {
            if (Array.isArray(value)) {
                const index = value.findIndex((x) => x.userId === data.userId);
                if (index !== -1) {
                    value[index] = data;
                } else {
                    value.push(data);
                }
                this.localStorageService.setItem(key, value);
            } else {
                this.localStorageService.setItem(key, [data]);
            }
        });
    }

    private getLastVisitedUserRouteFromLocalStorageValue(value: UserLastVisitedRoute[]): string {
        const userLastVisitedRoute = value.find((x) => x.userId === this.userId);
        return userLastVisitedRoute?.url;
    }
}
