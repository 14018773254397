import { Injector, Component, OnInit, Inject, Renderer2, ViewChild, ElementRef, AfterViewChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@app/shared/layout/directives/offcanvas.directive';
import { AppConsts } from '@shared/AppConsts';
import { ToggleOptions } from '@app/shared/layout/directives/toggle.directive';
import { NavigationEnd, Router } from '@angular/router';
import { getProjectId } from '@shared/utils/project.util';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserPermissionsRefreshService } from '@shared/services/user-permissions-refresh.service';
import { LocalStorageService } from '@shared/utils/services/local-storage.service';
import { Observable, Subject } from 'rxjs';
import { concatMap, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { SensorType } from '@shared/service-proxies/service-proxies';
import { PangeaAsideSensorTypeService } from '../pangea-aside-sensor-type.service';
import { AppComponentBase } from '@shared/common/app-component-base';

type MenuItem = {
    path?: string;
    title?: string;
    icon?: string;
    permissions?: string[];
    isShowItems?: boolean;
    items?: MenuItem[];
};

const SIDE_MENU_STATE_STORAGE_KEY = 'SideMenuState';

@Component({
    selector: 'app-pangea-layout',
    templateUrl: './pangea-layout.component.html',
    styleUrls: ['./pangea-layout.component.scss'],
})
export class PangeaLayoutComponent extends AppComponentBase implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild('kt_aside') asideContainer: ElementRef<HTMLDivElement>;

    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle',
    };

    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active',
    };
    remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
    isOutsideProject = true;
    topMenu: MenuItem;
    sideMenu: MenuItem;
    mainUrl = 'app/projects';
    sensorTypeFormControl = new FormControl(SensorType.Tps);
    sensorTypeEnum = SensorType;
    isMinWidth992Px: boolean;

    private destroy$ = new Subject<void>();

    get pangeaLogoUrl(): string {
        return './assets/common/images/amasia/amasia-secondary.png';
    }

    get contentWrapperStyle(): Record<string, string> {
        if (!this.sideMenu) {
            return { 'padding-left': '0px' };
        }
        if (this.sideMenu && !this.isAssideMinimized) {
            return {};
        }
        if (this.sideMenu && this.isAssideMinimized && this.isMinWidth992Px) {
            return { 'padding-left': '70px' };
        }
        return {};
    }

    get headerStyle(): Record<string, string> {
        if (!this.sideMenu) {
            return { left: '0px' };
        }
        if (this.sideMenu && !this.isAssideMinimized) {
            return { left: '265px' };
        }
        if (this.sideMenu && this.isAssideMinimized) {
            return {};
        }
        return {};
    }

    get sensorTypeDropdownAvailable(): boolean {
        if (!this.isAssideMinimized) {
            return (
                !!this.router.url.match(/\/app\/projects\/\d+\/tps\/configuration-board\//) ||
                !!this.router.url.match(/\/app\/projects\/\d+\/weather-station\/configuration-board\//) ||
                !!this.router.url.match(/\/app\/projects\/\d+\/gnss\/configuration-board\//)
            );
        } else {
            if (this.isAssideOn) {
                return (
                    !!this.router.url.match(/\/app\/projects\/\d+\/tps\/configuration-board\//) ||
                    !!this.router.url.match(/\/app\/projects\/\d+\/weather-station\/configuration-board\//) ||
                    !!this.router.url.match(/\/app\/projects\/\d+\/gnss\/configuration-board\//)
                );
            } else {
                return false;
            }
        }
    }

    get isAssideOn(): boolean {
        return this.document.getElementById('kt_aside').classList.contains('aside-on');
    }

    get menuButtonAvailable(): boolean {
        return (
            !this.router.url.endsWith('app/projects') &&
            !this.router.url.endsWith('app/welcome') &&
            !this.router.url.endsWith('map-view') &&
            !this.router.url.endsWith('upload-layer') &&
            !this.router.url.includes('weather-dashboards')
        );
    }

    get isAssideMinimized(): boolean {
        return this.document.body.classList.contains('aside-minimize');
    }

    get menuDropdownClass(): string {
        if (this.isMinWidth992Px) {
            return !this.isAssideMinimized ? 'menu-dropdown-with-chevron' : 'menu-dropdown-without-chevron';
        } else {
            return 'menu-dropdown-with-chevron';
        }
    }

    get asideMenuStyle(): Record<string, string> {
        if (this.asideContainer) {
            const gap = 20;
            const amasiaTopBrandHeight = this.asideContainer.nativeElement.querySelector('app-pangea-brand')?.clientHeight ?? 0;
            const amasiaFooterBrandHeight = this.asideContainer.nativeElement.querySelector('.amasia-footer-brand')?.clientHeight ?? 0;
            const sensorTypeDropdownHeight = this.asideContainer.nativeElement.querySelector('.sensor-type-dropdown-container')?.clientHeight ?? 0;
            return {
                height: `${window.innerHeight - amasiaTopBrandHeight - sensorTypeDropdownHeight - amasiaFooterBrandHeight - gap}px`,
            };
        } else {
            return {
                height: '100%',
            };
        }
    }

    get isShowAllMenuItems(): boolean {
        return this.isMinWidth992Px ? !this.isAssideMinimized : true;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        injector: Injector,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private render: Renderer2,
        private cdRef: ChangeDetectorRef,
        private localStorageService: LocalStorageService,
        private userPermissionsRefreshService: UserPermissionsRefreshService,
        private pangeaAsideSensorTypeService: PangeaAsideSensorTypeService,
    ) {
        super(injector);
        this.resolveSensorType();
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter((event) => event instanceof NavigationEnd),
                tap(() => this.resolveSensorType()),
                concatMap(() => this.initMenu()),
            )
            .subscribe();

        this.breakpointObserver
            .observe(['(min-width: 992px)'])
            .pipe(takeUntil(this.destroy$))
            .subscribe((state) => {
                if (state.matches) {
                    this.isMinWidth992Px = true;
                } else {
                    this.isMinWidth992Px = false;
                }
            });

        this.userPermissionsRefreshService.permissionsChanged$
            .pipe(
                takeUntil(this.destroy$),
                concatMap(() => this.initMenu()),
            )
            .subscribe();

        this.sensorTypeFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((sensorType: SensorType) => {
            this.pangeaAsideSensorTypeService.setSensorType(sensorType);
            const projectId = getProjectId(this.router);
            this.router.navigate([`/app/projects/${projectId}/${this.pangeaAsideSensorTypeService.sensorTypeRoute}/configuration-board`]);
        });

        this.initMenu().pipe(take(1)).subscribe();

        this.getIconUrl();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    initMenu(): Observable<void> {
        return this.getSideMenuState().pipe(
            take(1),
            tap((state) => this.getMenu(state)),
            map(() => null),
        );
    }

    private resolveSensorType(): void {
        const sensorType = this.getRouteSensorType();
        if (sensorType) {
            this.sensorTypeFormControl.setValue(sensorType, { emitEvent: false });
            this.pangeaAsideSensorTypeService.setSensorType(sensorType);
        }
    }

    private getRouteSensorType(): SensorType {
        if (this.router.url.match(/\/app\/projects\/\d+\/tps\//)) {
            return SensorType.Tps;
        } else if (this.router.url.match(/\/app\/projects\/\d+\/weather-station\//)) {
            return SensorType.Weather;
        } else if (this.router.url.match(/\/app\/projects\/\d+\/gnss\//)) {
            return SensorType.Gnss;
        } else {
            return null;
        }
    }

    getIconUrl(): void {
        if (this.permission.isGranted(this.permissionsConsts.Pages_Projects)) {
            this.mainUrl = 'projects';
        } else if (this.permission.isGranted(this.permissionsConsts.Pages_UniversalSettings)) {
            this.mainUrl = 'universal-settings';
        } else if (this.permission.isGranted(this.permissionsConsts.Pages_RolesAndUsers)) {
            this.mainUrl = 'admin/roles-and-users';
        } else if (this.permission.isGranted(this.permissionsConsts.Pages_Licenses)) {
            this.mainUrl = 'license-manager';
        } else {
            this.mainUrl = 'welcome';
        }
    }

    getMenu(menuItemsState: MenuItem[]): void {
        if (
            !this.router.url.match(/archived-projects/) &&
            this.router.url.match(/projects\/[^\n]+/) &&
            !this.router.url.match(/create-project/) &&
            !this.router.url.match(/edit-project/)
        ) {
            const projectId = getProjectId(this.router);
            this.isOutsideProject = false;

            const tpsConfigurationBoardItems: MenuItem[] = [
                {
                    path: '/app/projects/' + projectId + '/tps/configuration-board/control-table',
                    title: 'ControlTableMenu',
                    icon: 'flaticon-control-table',
                    permissions: [this.permissionsConsts.Pages_Projects_ReferencePoints, this.permissionsConsts.Pages_Projects_InstrumentPillars],
                    isShowItems: menuItemsState.find((state) => state.title === 'ControlTableMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/control-table/reference-point',
                            permissions: [this.permissionsConsts.Pages_Projects_ReferencePoints],
                            title: 'ReferencePointMenu',
                            icon: 'flaticon-reference-point',
                        },
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/control-table/instrument-pillar',
                            permissions: [this.permissionsConsts.Pages_Projects_InstrumentPillars],
                            title: 'InstrumentPillarMenu',
                            icon: 'flaticon-instrument-pillar',
                        },
                    ],
                },
                {
                    path: '/app/projects/' + projectId + '/tps/configuration-board/points-and-prisms-manager',
                    title: 'PointsPrismsManagerMenu',
                    icon: 'flaticon-points-prisms-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_TargetPoints],
                    isShowItems: menuItemsState.find((state) => state.title === 'PointsPrismsManagerMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/points-and-prisms-manager/new-points-prisms-table-view',
                            title: 'NewPointsPrismsMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_TargetPoints],
                            icon: 'flaticon-new-points-prisms',
                        },
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/points-and-prisms-manager/view',
                            title: 'PointsPrismsMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_TargetPoints],
                            icon: 'flaticon-points-prisms',
                        },
                    ],
                },
                {
                    path: '/app/projects/' + projectId + '/tps/configuration-board/group-manager',
                    title: 'GroupManagerMenu',
                    icon: 'flaticon-group-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_Groups],
                },
                {
                    path: '/app/projects/' + projectId + '/tps/configuration-board/sensor-manager',
                    title: 'SensorManagerMenu',
                    icon: 'flaticon-sensor-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_TpsSensors],
                },
                {
                    path: '/app/projects/' + projectId + '/tps/configuration-board/station-manager',
                    title: 'StationManagerMenu',
                    icon: 'flaticon-station-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_Stations],
                    isShowItems: menuItemsState.find((state) => state.title === 'StationManagerMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/station-manager/station-list',
                            title: 'StationListMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_Stations],
                            icon: 'flaticon-station-list',
                        },
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/station-manager/station-activity',
                            title: 'StationActivityMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_Stations],
                            icon: 'flaticon-station-activity',
                        },
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/station-manager/station-queue',
                            title: 'StationQueueMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_Stations],
                            icon: 'flaticon-station-queue',
                        },
                    ],
                },
                {
                    path: '/app/projects/' + projectId + '/tps/configuration-board/schedule-manager',
                    title: 'ScheduleManagerMenu',
                    icon: 'flaticon-schedule-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_Schedules],
                    isShowItems: menuItemsState.find((state) => state.title === 'ScheduleManagerMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/schedule-manager/schedule-overview',
                            title: 'ScheduleOverviewMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_Schedules],
                            icon: 'flaticon-schedule-overview',
                        },
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/schedule-manager/scheduler',
                            title: 'SchedulerMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_Schedules],
                            icon: 'flaticon-scheduler',
                        },
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/schedule-manager/schedule-settings',
                            title: 'ScheduleSettingsMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_Schedules],
                            icon: 'flaticon-schedule-settings',
                        },
                    ],
                },
                {
                    path: '/app/projects/' + projectId + '/tps/configuration-board/threshold-profiles',
                    title: 'ThresholdProfilesManagerMenu',
                    icon: 'flaticon-threshold-profiles',
                    permissions: [this.permissionsConsts.Pages_Projects_ThresholdProfiles],
                    isShowItems: menuItemsState.find((state) => state.title === 'ThresholdProfilesManagerMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/threshold-profiles/movement-profile',
                            title: 'MovementProfileMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_ThresholdProfiles],
                            icon: 'flaticon-movement-profile',
                        },
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/threshold-profiles/measurement-profile',
                            title: 'MeasurementProfileMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_ThresholdProfiles],
                            icon: 'flaticon-measurement-profile',
                        },
                        {
                            path: '/app/projects/' + projectId + '/tps/configuration-board/threshold-profiles/instrument-profile',
                            title: 'InstrumentProfileMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_ThresholdProfiles],
                            icon: 'flaticon-instrument-profile',
                        },
                    ],
                },
            ];

            const weatherStationConfigurationBoardItems: MenuItem[] = [
                {
                    path: '/app/projects/' + projectId + '/weather-station/configuration-board/sensor-manager',
                    title: 'SensorManagerMenu',
                    icon: 'flaticon-sensor-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_WeatherSensors],
                },
                {
                    path: '/app/projects/' + projectId + '/weather-station/configuration-board/station-manager',
                    title: 'StationManagerMenu',
                    icon: 'flaticon-station-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_WeatherStations],
                    isShowItems: menuItemsState.find((state) => state.title === 'StationManagerMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/weather-station/configuration-board/station-manager/station-list',
                            title: 'StationListMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_WeatherStations],
                            icon: 'flaticon-station-list',
                        },
                        {
                            path: '/app/projects/' + projectId + '/weather-station/configuration-board/station-manager/station-activity',
                            title: 'StationActivityMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_WeatherStations],
                            icon: 'flaticon-station-activity',
                        },
                    ],
                },
                {
                    path: '/app/projects/' + projectId + '/weather-station/configuration-board/schedule-manager',
                    title: 'ScheduleManagerMenu',
                    icon: 'flaticon-schedule-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_WeatherSchedules],
                    isShowItems: menuItemsState.find((state) => state.title === 'ScheduleManagerMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/weather-station/configuration-board/schedule-manager/schedule-overview',
                            title: 'ScheduleOverviewMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_WeatherSchedules],
                            icon: 'flaticon-schedule-overview',
                        },
                        {
                            path: '/app/projects/' + projectId + '/weather-station/configuration-board/schedule-manager/scheduler',
                            title: 'SchedulerMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_WeatherSchedules],
                            icon: 'flaticon-scheduler',
                        },
                    ],
                },
            ];

            const gnssConfigurationBoardItems: MenuItem[] = [
                {
                    path: '/app/projects/' + projectId + '/gnss/configuration-board/control-table',
                    title: 'ControlTableMenu',
                    icon: 'flaticon-control-table',
                    permissions: [this.permissionsConsts.Pages_Projects_ReferencePoints, this.permissionsConsts.Pages_Projects_InstrumentPillars],
                    isShowItems: menuItemsState.find((state) => state.title === 'ControlTableMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/gnss/configuration-board/control-table/reference-point',
                            permissions: [this.permissionsConsts.Pages_Projects_ReferencePoints],
                            title: 'ReferencePointMenu',
                            icon: 'flaticon-reference-point',
                        },
                        {
                            path: '/app/projects/' + projectId + '/gnss/configuration-board/control-table/instrument-pillar',
                            permissions: [this.permissionsConsts.Pages_Projects_InstrumentPillars],
                            title: 'InstrumentPillarMenu',
                            icon: 'flaticon-instrument-pillar',
                        },
                    ],
                },
                {
                    path: '/app/projects/' + projectId + '/gnss/configuration-board/sensor-manager',
                    title: 'SensorManagerMenu',
                    icon: 'flaticon-sensor-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_GnssSensors],
                },
                {
                    path: '/app/projects/' + projectId + '/gnss/configuration-board/station-manager',
                    title: 'StationManagerMenu',
                    icon: 'flaticon-station-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_GnssStations],
                    isShowItems: menuItemsState.find((state) => state.title === 'StationManagerMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/gnss/configuration-board/station-manager/station-list',
                            title: 'StationListMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_GnssStations],
                            icon: 'flaticon-station-list',
                        },
                        {
                            path: '/app/projects/' + projectId + '/gnss/configuration-board/station-manager/station-activity',
                            title: 'StationActivityMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_GnssStations],
                            icon: 'flaticon-station-activity',
                        },
                    ],
                },
                {
                    path: '/app/projects/' + projectId + '/gnss/configuration-board/schedule-manager',
                    title: 'ScheduleManagerMenu',
                    icon: 'flaticon-schedule-manager',
                    permissions: [this.permissionsConsts.Pages_Projects_GnssSchedules],
                    isShowItems: menuItemsState.find((state) => state.title === 'ScheduleManagerMenu')?.isShowItems ?? true,
                    items: [
                        {
                            path: '/app/projects/' + projectId + '/gnss/configuration-board/schedule-manager/schedule-overview',
                            title: 'ScheduleOverviewMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_GnssSchedules],
                            icon: 'flaticon-schedule-overview',
                        },
                        {
                            path: '/app/projects/' + projectId + '/gnss/configuration-board/schedule-manager/scheduler',
                            title: 'SchedulerMenu',
                            permissions: [this.permissionsConsts.Pages_Projects_GnssSchedules],
                            icon: 'flaticon-scheduler',
                        },
                    ],
                },
            ];

            let configurationBoardItems: MenuItem[];
            switch (this.sensorTypeFormControl.value) {
                case SensorType.Tps:
                    configurationBoardItems = tpsConfigurationBoardItems;
                    break;
                case SensorType.Weather:
                    configurationBoardItems = weatherStationConfigurationBoardItems;
                    break;
                case SensorType.Gnss:
                    configurationBoardItems = gnssConfigurationBoardItems;
                    break;
            }

            const configurationBoards: MenuItem = {
                path: `/app/projects/${projectId}/${this.pangeaAsideSensorTypeService.sensorTypeRoute}/configuration-board`,
                title: 'ConfigurationBoardMenu',
                items: configurationBoardItems,
            };

            const mapView: MenuItem = {
                path: '/app/projects/' + projectId + '/map-view',
                title: 'MapViewMenu',
                permissions: [this.permissionsConsts.Pages_Projects_MapView],
                items: [],
            };

            const weather: MenuItem = {
                path: '/app/projects/' + projectId + '/weather-dashboards',
                title: 'WeatherMenu',
                permissions: [this.permissionsConsts.Pages_Projects_WeatherDashboard],
                items: [],
            };

            const thresholdAndActions: MenuItem = {
                path: '/app/projects/' + projectId + '/threshold-and-actions',
                title: 'ThresholdAndActionsMenu',
                items: [
                    {
                        path: '/app/projects/' + projectId + '/threshold-and-actions/threshold-profiles',
                        title: 'ThresholdProfilesMenu',
                        icon: 'flaticon-threshold-profiles',
                        permissions: [this.permissionsConsts.Pages_Projects_ThresholdProfiles],
                    },
                    {
                        path: '/app/projects/' + projectId + '/threshold-and-actions/action-manager',
                        title: 'ActionManagerMenu',
                        icon: 'flaticon-action-manager',
                        permissions: [this.permissionsConsts.Pages_Projects_Actions],
                    },
                    {
                        path: '/app/projects/' + projectId + '/threshold-and-actions/alarm-check-logs',
                        title: 'AlarmCheckLogsMenu',
                        icon: 'flaticon-alarm-check-log',
                        permissions: [this.permissionsConsts.Pages_Projects_ThresholdProfiles],
                    },
                    {
                        path: '/app/projects/' + projectId + '/threshold-and-actions/alarm-notifications',
                        title: 'AlarmNotificationsMenu',
                        icon: 'flaticon-alarm-notifications',
                    },
                ],
            };

            const projectLog: MenuItem = {
                path: '/app/projects/' + projectId + '/project-log',
                title: 'ProjectLogMenu',
                items: [
                    {
                        path: '/app/projects/' + projectId + '/project-log',
                        title: 'LogMenu',
                        icon: 'flaticon-project-log',
                    },
                ],
            };
            this.topMenu = { items: [configurationBoards, mapView, weather, thresholdAndActions, projectLog] };
        } else {
            this.isOutsideProject = true;
            const licenseManager: MenuItem = {
                title: 'LicenseManagerMenu',
                permissions: [this.permissionsConsts.Pages_Licenses],
                path: '/app/license-manager',
                items: [
                    {
                        title: 'OverviewMenu',
                        icon: 'flaticon-license-overview',
                        path: '/app/license-manager/overview',
                    },
                    {
                        title: 'LicenseFilesMenu',
                        icon: 'flaticon-license-files',
                        path: '/app/license-manager/files',
                    },
                    {
                        title: 'LicensesMenu',
                        icon: 'flaticon-licenses',
                        path: '/app/license-manager/licenses/view',
                    },
                ],
            };
            const universalSettings: MenuItem = {
                title: 'UniversalSettingsMenu',
                permissions: [this.permissionsConsts.Pages_UniversalSettings],
                path: '/app/universal-settings/',
                items: [
                    {
                        title: 'ConnectionListMenu',
                        permissions: [this.permissionsConsts.Pages_UniversalSettings_Connections],
                        icon: 'flaticon-connection-list',
                        path: '/app/universal-settings/connections',
                    },
                    {
                        title: 'SettingsMenu',
                        permissions: [this.permissionsConsts.Pages_UniversalSettings_Settings],
                        icon: 'flaticon-settings',
                        path: '/app/universal-settings/settings',
                    },
                ],
            };
            const rolesAndUsers: MenuItem = {
                title: 'RolesAndUsersMenu',
                permissions: [this.permissionsConsts.Pages_RolesAndUsers],
                path: '/app/admin/roles-and-users',
                items: [
                    {
                        title: 'RolesMenu',
                        permissions: [this.permissionsConsts.Pages_RolesAndUsers_Roles],
                        icon: 'flaticon-roles',
                        path: '/app/admin/roles-and-users/roles',
                    },
                    {
                        title: 'UsersMenu',
                        permissions: [this.permissionsConsts.Pages_RolesAndUsers_Users],
                        icon: 'flaticon-users',
                        path: '/app/admin/roles-and-users/users',
                    },
                    {
                        title: 'ProjectMembersMenu',
                        permissions: [this.permissionsConsts.Pages_RolesAndUsers_ProjectMembers],
                        icon: 'flaticon-project-members',
                        path: '/app/admin/roles-and-users/project-members',
                    },
                ],
            };
            const systemLog: MenuItem = {
                title: 'SystemHealthMenu',
                permissions: [this.permissionsConsts.Pages_SystemHealth],
                path: '/app/system-health',
                items: [
                    {
                        title: 'SystemLogsMenu',
                        permissions: [this.permissionsConsts.Pages_SystemHealth_SystemLog],
                        icon: 'flaticon-system-log',
                        path: '/app/system-health/system-log',
                    },
                    {
                        title: 'SchedulerServiceMenu',
                        permissions: [this.permissionsConsts.Pages_SystemHealth_SchedulerService],
                        icon: 'flaticon-scheduler-service',
                        path: '/app/system-health/scheduler-service',
                    },
                    {
                        title: 'WorkerServiceMenu',
                        permissions: [this.permissionsConsts.Pages_SystemHealth_WorkerService],
                        icon: 'flaticon-worker-service',
                        path: '/app/system-health/worker-service',
                    },
                    {
                        title: 'AlarmerServiceMenu',
                        permissions: [this.permissionsConsts.Pages_SystemHealth_AlarmerService],
                        icon: 'flaticon-alarmer-service',
                        path: '/app/system-health/alarmer-service',
                    },
                    {
                        title: 'LicenseServiceMenu',
                        permissions: [this.permissionsConsts.Pages_SystemHealth_LicenseService],
                        icon: 'flaticon-license-service',
                        path: '/app/system-health/license-service',
                    },
                ],
            };
            const projects: MenuItem = {
                title: 'ProjectsOverviewMenu',
                permissions: [this.permissionsConsts.Pages_Projects],
                path: '/app/projects',
                items: [],
            };
            this.topMenu = { items: [projects, universalSettings, licenseManager, rolesAndUsers, systemLog] };
        }
        this.sideMenu = null;
        this.topMenu.items.forEach((item) => {
            const hasActivatedRoute = item.items.find((sideItem) => this.router.url.includes(sideItem.path));

            if (hasActivatedRoute) {
                this.sideMenu = item;
            }
        });
    }

    interactMenuItem(item: MenuItem, e: Event): void {
        this.saveMenuItemState(item);
        e.preventDefault();
        if (item.items) {
            item.isShowItems = !item.isShowItems;
            if (this.isAssideMinimized && this.isMinWidth992Px) {
                this.router.navigate([item.items[0].path]);
            }
        } else {
            this.router.navigate([item.path]);
        }
    }

    checkIsActiveUrl(item: MenuItem): boolean {
        return this.router.url.includes(item.path);
    }

    checkIsActiveUrlWhenAsideMinimized(item: MenuItem): boolean {
        return this.isAssideMinimized ? this.router.url.includes(item.path) : false;
    }

    redirectBySensorType(event: unknown): void {
        this.router.navigate([event]);
    }

    asideMouseEnter(): void {
        if (document.body.classList.contains('aside-minimize')) {
            this.render.addClass(document.body, 'aside-minimize-hover');
        }
    }

    asideMouseLeave(): void {
        this.render.removeClass(document.body, 'aside-minimize-hover');
    }

    showAsideSubMenu(item: MenuItem): boolean {
        if (this.isMinWidth992Px) {
            return !this.isAssideMinimized && item.items && item.isShowItems;
        } else {
            return item.items && item.isShowItems;
        }
    }

    getSideMenuState(): Observable<MenuItem[]> {
        return new Observable((observer) => {
            this.localStorageService.getItem(SIDE_MENU_STATE_STORAGE_KEY, (error: unknown, value: MenuItem[]) => {
                observer.next(value ?? []);
            });
        });
    }

    saveMenuItemState(item: MenuItem): void {
        this.localStorageService.getItem(SIDE_MENU_STATE_STORAGE_KEY, (error: unknown, value: MenuItem[]) => {
            if (Array.isArray(value)) {
                const items = [
                    ...value.map((val) => {
                        return { title: val.title, isShowItems: val.isShowItems };
                    }),
                ];
                const itemIndex = items.findIndex((i) => i.title === item.title);
                if (itemIndex >= 0) {
                    items[itemIndex].isShowItems = item.isShowItems;
                } else {
                    items.push({ title: item.title, isShowItems: item.isShowItems });
                }
                this.localStorageService.setItem(SIDE_MENU_STATE_STORAGE_KEY, items);
            } else {
                this.localStorageService.setItem(SIDE_MENU_STATE_STORAGE_KEY, [{ title: item.title, isShowItems: item.isShowItems }]);
            }
        });
    }

    resolveSensorTypeName(sensorType: SensorType): string {
        switch (sensorType) {
            case SensorType.Tps:
                return this.l('Tps');
            case SensorType.Weather:
                return this.l('WeatherStation');
            case SensorType.Gnss:
                return this.l('GNSS');
        }
    }
}
