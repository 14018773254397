import { Injector, Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { PangeaAsideService } from '../pangea-aside.service';

@Component({
    selector: 'app-pangea-brand',
    templateUrl: './pangea-brand.component.html',
    styleUrls: ['./pangea-brand.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PangeaBrandComponent extends AppComponentBase implements OnInit {
    public mainUrl = 'app/projects';

    public get amasiaLogoUrl(): string {
        return './assets/common/images/amasia/amasia-secondary.png';
    }

    public get tenantLogoUrl(): string {
        return AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetLogo?tenantId=' + this.appSession.tenant.id;
    }

    public get brandContainerStyle(): { [key: string]: string } {
        if (
            this.router.url.endsWith('app/projects') ||
            this.router.url.endsWith('/map-view') ||
            this.router.url.endsWith('/upload-layer') ||
            this.router.url.includes('/weather-dashboards')
        ) {
            if (this.isAssideMinimized) {
                return { width: '265px', 'align-items': 'flex-start' };
            } else {
                return { width: '265px' };
            }
        } else {
            if (this.isAssideMinimized) {
                return { width: 'auto' };
            } else {
                return {};
            }
        }
    }

    public get brandImageStyle(): { [key: string]: string } {
        if (!this.isAssideMinimized) {
            return {};
        } else {
            if (
                this.router.url.endsWith('app/projects') ||
                this.router.url.endsWith('/map-view') ||
                this.router.url.endsWith('/upload-layer') ||
                this.router.url.includes('/weather-dashboards')
            ) {
                return { display: 'block' };
            } else {
                return { display: 'none' };
            }
        }
    }

    public get isAssideMinimized(): boolean {
        return this.pangeaAsideService.isAssideMinimized;
    }

    public get isAssideMinimizeHover(): boolean {
        return this.document.body.classList.contains('aside-minimize-hover');
    }

    public get isToggleAssideAvailable(): boolean {
        return (
            !this.router.url.endsWith('projects') &&
            !this.router.url.endsWith('create-project') &&
            !this.router.url.endsWith('edit-project') &&
            !this.router.url.endsWith('map-view') &&
            !this.router.url.endsWith('upload-layer') &&
            !this.router.url.endsWith('welcome') &&
            !this.router.url.includes('weather-dashboards')
        );
    }

    public get isReturnToHomeButtonAvailable(): boolean {
        return (
            this.isAssideMinimized &&
            !this.router.url.endsWith('projects') &&
            !this.router.url.endsWith('create-project') &&
            !this.router.url.endsWith('edit-project') &&
            !this.router.url.endsWith('map-view') &&
            !this.router.url.endsWith('upload-layer') &&
            !this.router.url.endsWith('welcome') &&
            !this.router.url.includes('weather-dashboards')
        );
    }

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private pangeaAsideService: PangeaAsideService,
    ) {
        super(injector);
    }

    public ngOnInit(): void {
        this.getIconUrl();
    }

    public getIconUrl(): void {
        if (this.isGranted(this.permissionsConsts.Pages_Projects)) {
            this.mainUrl = 'projects';
        } else if (this.isGranted(this.permissionsConsts.Pages_UniversalSettings)) {
            this.mainUrl = 'universal-settings';
        } else if (this.isGranted(this.permissionsConsts.Pages_RolesAndUsers)) {
            this.mainUrl = 'admin/roles-and-users';
        } else {
            this.mainUrl = 'welcome';
        }
    }

    public toggleAside(): void {
        this.pangeaAsideService.toggle();
    }

    public returnToHome(): void {
        this.router.navigate([this.mainUrl]);
    }
}
