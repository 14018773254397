import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decimalLimit'
})
export class DecimalLimitPipe implements PipeTransform {
    transform(value: number, decimalPlaces: number): string {
        if (isNaN(value)) return null;
        const fixedValue = parseFloat(value.toFixed(decimalPlaces)).toString();
        return fixedValue;
    }
}
