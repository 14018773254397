import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PangeaSwitchComponent } from './pangea-switch.component';

@NgModule({
    declarations: [PangeaSwitchComponent],
    imports: [CommonModule, FormsModule],
    exports: [PangeaSwitchComponent],
})
export class PangeaSwitchModule {}
