import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[notEmptyString]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmptyStringDirective, multi: true }],
})
export class EmptyStringDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const value = control.value as string;
        if (value && !value.trim().length) {
            return { emptyString: true };
        }
        return null;
    }
}
