import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppUiCustomizationService } from './ui/app-ui-customization.service';
import { AppSessionService } from './session/app-session.service';
import { CookieConsentService } from './session/cookie-consent.service';
import { PangeaAsideService } from '@app/shared/layout/themes/pangea/pangea-aside.service';
import { PangeaAsideSensorTypeService } from '@app/shared/layout/themes/pangea/pangea-aside-sensor-type.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [],
    declarations: []
})
export class HubCommonModule {
    static forRoot(): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [
                PangeaAsideService,
                PangeaAsideSensorTypeService,
                AppUiCustomizationService,
                CookieConsentService,
                AppSessionService,
            ]
        };
    }
}
