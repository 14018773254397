import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToLocalDateTimeString',
})
export class DateToLocalDateTimeStringPipe implements PipeTransform {
    transform(value: moment.Moment): string {
        if (!value) return '';
        return new Date(value.valueOf()).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
    }
}
