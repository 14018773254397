import { StyleLoaderService } from '@shared/utils/services/style-loader.service';

export class DynamicResourcesHelper {
    static loadResources(callback: () => void): void {
        Promise.all([DynamicResourcesHelper.loadStyles()]).then(() => {
            callback();
        });
    }

    static loadStyles(): Promise<boolean> {
        const styleLoaderService = new StyleLoaderService();

        const styleUrls = [
            './assets/metronic/themes/pangea/css/style.bundle.css',
            './assets/primeng/datatable/css/primeng.datatable.css',
            './assets/common/styles/metronic-customize.css',
            './assets/common/styles/metronic-customize-angular.css',
            './assets/ngx-bootstrap/bs-datepicker.css',
        ];

        styleLoaderService.loadArray(styleUrls);

        return Promise.resolve(true);
    }
}
