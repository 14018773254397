<div class="alarm-notification-icon">
    <ng-container *ngIf="notificationsCount; else noNotifications">
        <a class="btn btn-icon btn-clean" routerLink="/app/projects/{{projectId}}/threshold-and-actions/alarm-notifications">
            <i class="flaticon-alert-2 icon"></i>
            <span class="label label-warning notification-count" *ngIf="!isLoading">
                {{ notificationsCount }}
            </span>
            <span class="spinner-container" *ngIf="isLoading">
                <i class="fa fa-spin fa-spinner"></i>
            </span>
        </a>
    </ng-container>
    <ng-template #noNotifications>
        <a class="btn btn-icon btn-clean" routerLink="/app/projects/{{projectId}}/threshold-and-actions/alarm-notifications">
            <i class="flaticon-alarm icon"></i>
            <span class="spinner-container" *ngIf="isLoading">
                <i class="fa fa-spin fa-spinner"></i>
            </span>
        </a>
    </ng-template>
</div>