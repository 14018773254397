import { AbpMultiTenancyService, AbpSessionService } from 'abp-ng2-module';
import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent extends AppComponentBase implements OnInit, AfterViewInit {
    private readonly defaultProfilePicture = './assets/common/images/default-profile-picture.png';

    @Input() iconOnly = false;

    @Input() togglerCssClass = 'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2';
    @Input() textCssClass = 'text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3';
    @Input() symbolCssClass = 'symbol symbol-35 symbol-light-success';
    @Input() symbolTextCssClass = 'symbol-label font-size-h5 font-weight-bold';

    usernameFirstLetter = '';

    profilePicture = this.defaultProfilePicture;
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    userSurname = '';
    userRoles = '';

    isImpersonatedLogin = false;
    isMultiTenancyEnabled = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mQuickUserOffcanvas: any;

    public constructor(
        injector: Injector,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _authService: AppAuthService,
        private _abpSessionService: AbpSessionService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.setCurrentLoginInformations();
        this.getProfilePicture();
        this.registerToEvents();
        this.usernameFirstLetter = this.appSession.user.userName.substring(0, 1).toUpperCase();
    }

    ngAfterViewInit(): void {
        this.mQuickUserOffcanvas = new KTOffcanvas('kt_quick_user', {
            overlay: true,
            baseClass: 'offcanvas',
            placement: 'right',
            closeBy: 'kt_demo_panel_close',
            toggleBy: 'kt_quick_user_toggle',
        });

        this.mQuickUserOffcanvas.events.push({
            name: 'beforeShow',
            handler: () => {
                abp.event.trigger('app.show.quickUserPanel');
            },
        });
    }

    setCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user.name;
        this.userSurname = this.appSession.user.surname;
        this.userRoles = this.getRolesAsString(this.appSession.user.roles);
    }

    getRolesAsString(roles: string[]): string {
        let roleNames = '';

        for (let j = 0; j < roles.length; j++) {
            if (roleNames.length) {
                roleNames = roleNames + ', ';
            }

            roleNames = roleNames + roles[j];
        }

        return roleNames;
    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe((result) => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            } else {
                this.profilePicture = this.defaultProfilePicture;
            }
        });
    }

    showLinkedAccounts(): void {
        abp.event.trigger('app.show.linkedAccountsModal');
    }

    showUserDelegations(): void {
        abp.event.trigger('app.show.userDelegationsModal');
    }

    changePassword(): void {
        abp.event.trigger('app.show.changePasswordModal');
    }

    changeProfilePicture(): void {
        abp.event.trigger('app.show.changeProfilePictureModal');
    }

    changeProfileSettings(): void {
        abp.event.trigger('app.show.profileSettingsModal');
    }

    registerToEvents(): void {
        abp.event.on('profilePictureChanged', () => this.getProfilePicture());
        abp.event.on('app.profileSettingsSaved', () => this.setCurrentLoginInformations());
        abp.event.on('app.currentUserInfoChanged', () => this.setCurrentLoginInformations());
    }

    logout(): void {
        this.message.confirm(this.l('LogoutWarning'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this._authService.logout();
            }
        });
    }

    backToMyAccount(): void {}
}
