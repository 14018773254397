import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'millivoltsToVoltage'
})
export class MillivoltsToVoltagePipe implements PipeTransform {
    transform(value: number): number {
        if (isNaN(value)) return null;
        const calculatedValue = value/1000;
        return calculatedValue;
    }
}
