import { Injectable } from '@angular/core';
import { SensorType } from '@shared/service-proxies/service-proxies';

@Injectable()
export class PangeaAsideSensorTypeService {
    private _sensorType = SensorType.Tps;

    public get sensorType(): SensorType {
        return this._sensorType;
    }

    public get sensorTypeRoute(): 'tps' | 'weather-station' | 'gnss' {
        if (this.sensorType === SensorType.Tps) {
            return 'tps';
        } else if (this.sensorType === SensorType.Weather) {
            return 'weather-station';
        } else if (this.sensorType === SensorType.Gnss) {
            return 'gnss';
        } else {
            return null;
        }
    }

    public setSensorType(sensorType: SensorType): void {
        this._sensorType = sensorType;
    }
}
